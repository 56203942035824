import React, { useState, useEffect } from "react";

const Index = () => {
  const [price, setPrice] = useState(null);
  const [dot, setDOT] = useState(null);

  useEffect(() => {
    const webSocket = new WebSocket(
      "wss://acala-crowdloan-dashboard-khdse.ondigitalocean.app/"
    );
    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.acalaDot) {
        setDOT(data.acalaDot);
      }

      if (data.price) {
        setPrice(data.price);
      }
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-gray-50 w-full h-screen">
      <div className="flex flex-col justify-center items-start gap-6 w-80 md:w-3/4">
        <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
          <div className="text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-bold text-acala-blue">
            {dot ? `${dot.toLocaleString()} ` : "Loading "}
          </div>
          <div className="text-gray-500 md:text-xl lg:text-4xl md:pl-4 lg:pl-6">
            DOT Loaned
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
          <div className="text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-bold text-acala-blue">
            {price && dot
              ? `${(price * dot).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })} `
              : "Loading "}
          </div>
          <div className="text-gray-500 md:text-xl lg:text-4xl md:pl-4 lg:pl-6">
            Value Locked
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
