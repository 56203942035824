import React, { useEffect, useState } from "react";

const AUCTION_SAMPLES = 3600;

const VsPage = () => {
  const [acalaDot, setAcalaDot] = useState(null);
  const [moonDot, setMoonDot] = useState(null);
  const [price, setPrice] = useState(null);
  const [acalaNumWins, setAcalaNumWins] = useState(null);
  const [moonNumWins, setMoonNumWins] = useState(null);

  useEffect(() => {
    const webSocket = new WebSocket(
      "wss://acala-crowdloan-dashboard-khdse.ondigitalocean.app/"
    );

    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.acalaDot) {
        setAcalaDot(data.acalaDot);
      }

      if (data.moonDot) {
        setMoonDot(data.moonDot);
      }

      if (data.price) {
        setPrice(data.price);
      }

      if (data.acalaNumWins) {
        console.log(data.acalaNumWins);
        setAcalaNumWins(data.acalaNumWins);
      }

      if (data.moonNumWins) {
        setMoonNumWins(data.moonNumWins);
      }
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-gray-50 w-full h-screen">
      <div className="flex flex-col justify-center items-start gap-7 md:gap-12 lg:gap-16 w-80 md:w-3/4">
        <div className="flex flex-col justify-start items-start gap-2 md:gap-4 lg:gap-6">
          <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
            <div
              className={`${
                acalaDot < moonDot ? "text-opacity-30" : "font-bold"
              } text-3xl md:text-6xl lg:text-7xl xl:text-8xl text-acala-blue`}
            >
              {acalaDot ? `${acalaDot.toLocaleString()} ` : "Loading "}
            </div>
            <div
              className={`${
                acalaDot < moonDot ? "text-opacity-30" : ""
              } text-gray-500 md:text-xl lg:text-4xl md:pl-4 lg:pl-6`}
            >
              DOT Acala
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
            <div
              className={`${
                moonDot < acalaDot ? "text-opacity-30" : "font-bold"
              } text-3xl md:text-6xl lg:text-7xl xl:text-8xl text-acala-blue`}
            >
              {moonDot ? `${moonDot.toLocaleString()}` : "Loading "}
            </div>
            <div
              className={`${
                acalaDot > moonDot ? "text-opacity-30" : ""
              } text-gray-500 md:text-xl lg:text-4xl md:pl-4 lg:pl-6`}
            >
              DOT Moonbeam
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start gap-2 md:gap-4 lg:gap-6">
          <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
            <div className="text-opacity-80 text-3xl md:text-4xl lg:text-6xl xl:text-7xl font-bold text-acala-blue">
              {Math.abs(acalaDot - moonDot).toLocaleString()}
            </div>
            <div className="text-gray-500 md:text-lg lg:text-3xl md:pl-2 lg:pl-4">
              DOT Delta
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
            <div className="text-opacity-80 text-3xl md:text-4xl lg:text-6xl xl:text-7xl font-bold text-acala-blue">
              {(Math.abs(acalaDot - moonDot) * price).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
            <div className="text-gray-500 md:text-lg lg:text-3xl md:pl-2 lg:pl-4">
              USD Delta
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start gap-2 md:gap-4 lg:gap-6">
          <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
            <div className="text-opacity-80 text-3xl md:text-4xl lg:text-6xl xl:text-7xl text-acala-blue font-bold">
              {acalaNumWins
                ? (
                    (acalaNumWins / AUCTION_SAMPLES) *
                    100
                  ).toLocaleString("en-US", { maximumFractionDigits: 2 }) + "%"
                : "Loading"}
            </div>
            <div className="text-gray-500 md:text-lg lg:text-3xl md:pl-2 lg:pl-4">
              Acala Leading
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-start md:items-end">
            <div className="text-opacity-80 text-3xl md:text-4xl lg:text-6xl xl:text-7xl text-acala-blue font-bold">
              {acalaNumWins
                ? (
                    (moonNumWins / AUCTION_SAMPLES) *
                    100
                  ).toLocaleString("en-US", { maximumFractionDigits: 2 }) + "%"
                : "Loading"}
            </div>
            <div className="text-gray-500 md:text-lg lg:text-3xl md:pl-2 lg:pl-4">
              Moonbeam Leading
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VsPage;
